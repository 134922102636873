<template>
  <div class="box success">
    <div class="inner">
      <img src="@/assets/success.png" alt="" srcset="">
      <div v-if="type=='1'" class="info">地锁正在下降中，请稍后...</div>
      <template v-else>
        <div class="info">请在倒计时结束前离场，否则将重新计费</div>
        <div class="times">{{time|times}}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setInterval: null,
      type: "",
      time: 0,
    };
  },
  filters: {
    times: function (value) {
      if (value / 60 > 1) {
        let minute = Math.floor(value / 60);
        let secned = value - minute * 60;
        minute > 9 ? "" : (minute = `0${minute}`);
        secned > 9 ? "" : (secned = `0${secned}`);
        return `${minute} : ${secned}`;
      } else {
        value > 9 ? "" : (value = `0${value}`);
        return `00 : ${value}`;
      }
    },
  },
  created() {
    if (localStorage.seatNum) {
      this.$dialog
        .alert({
          title: "提示",
          message: "历史欠费已缴纳，感谢您的配合",
          theme: "round-button",
          confirmButtonText: "去支付当前停车订单",
        })
        .then(() => {
          this.$router.push("/order?seatNum=" + localStorage.seatNum);
        });
    }
    if (localStorage.orderId) {
      this.selectOrderOutFreeTime(localStorage.orderId);
    }
  },
  methods: {
    selectOrderOutFreeTime(orderId) {
      this.$http.get(
        "/park-service-order/order/selectOrderOutFreeTime",
        { orderId },
        (res) => {
          this.time = Math.floor(res.freeTime / 1000);
          if (res.type == "1") {
            this.type = res.type;
            this.setInterval = setInterval(() => {
              this.getLockStatus(orderId);
            }, 1000);
          } else {
            this.setInterval = setInterval(() => {
              if (this.time < 1) {
                clearInterval(this.setInterval);
              } else {
                this.time = this.time - 1;
              }
            }, 1000);
          }
        }
      );
    },
    getLockStatus(orderId) {
      this.$http.get(
        "park-service-order/order/getLockState",
        { orderId },
        (res) => {
          if (res.code == "200") {
            this.type = res.code;
            clearInterval(this.setInterval);
            this.setInterval = setInterval(() => {
              if (this.time < 1) {
                clearInterval(this.setInterval);
              } else {
                this.time = this.time - 1;
              }
            }, 1000);
          }
        },
        false
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  position: relative;
  background-color: #fff;
  .inner {
    position: absolute;
    z-index: 9;
    top: -4rem;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 6rem;
    height: 4rem;
    text-align: center;
    img {
      width: 3rem;
      height: 2.7rem;
    }
    .info {
      color: #454545;
      font-size: 0.3rem;
      font-weight: bold;
      padding: 0.6rem 0;
    }
    .times {
      color: #ff1c1c;
      font-size: 0.7rem;
    }
    .close {
      background-color: #04be02;
      color: #fff;
      padding: 0.2rem 0;
      width: 62%;
      margin: 0 auto;
      border-radius: 4px;
    }
  }
}
</style>
